<template>
  <div>
    <div>
      <div class="limit-item" v-if="!short">
        <div class="limit-legend" :style="getStyle(0, 0)"></div>
        Pas de limite
      </div>
      <div class="limit-item" v-if="!short">
        <div class="limit-legend" :style="getStyle(1, 10)"></div>
        Reste plus de 5 places
      </div>
      <div class="limit-item">
        <div class="limit-legend" :style="getStyle(5, 10)"></div>
        Reste 5 places ou moins
      </div>
      <div class="limit-item">
        <div class="limit-legend" :style="getStyle(10, 10)"></div>
        Complet
      </div>
      <div class="limit-item">
        <div class="limit-legend" :style="getStyle(11, 10)"></div>
        Sur-effectif
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'LimitsIndicatorLegend',
  components: {
  },
  mixins: [],
  props: {
    short: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    getStyle(value, maxValue) {
      if (value) {
        if (maxValue) {
          if (value > maxValue) {
            return {
              backgroundColor: '#e96eff',
              color: '#000',
            }
          } else if (value === maxValue) {
            return {
              backgroundColor: '#ff6e6e',
              color: '#000',
            }
          } else if (value >= (maxValue - 5)) {
            return {
              backgroundColor: '#ebe770',
              color: '#000',
            }
          } else {
            return {
              backgroundColor: '#e1ffdd',
              color: '#000',
            }
          }
        }
      }
      return {
        backgroundColor: '#fff',
        color: '#000',
      }
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
.limit-item {
  display: inline-block;
  padding: 2px 20px 2px 0;
}
.limit-legend {
  display: inline-block;
  border: solid 1px #444;
  padding: 2px 2px;
  min-height: 16px;
  min-width: 16px;
  font-size: 11px;
  margin: 1px 1px;
  text-align: center;
  border-radius: 4px;
}
@media (max-width: 1350px) {
  .limit-legend {
    padding: 3px 2px;
    min-width: 14px;
    font-size: 9px;
    margin: 1px 1px;
    text-align: center;
    border-radius: 4px;
  }
}
</style>
